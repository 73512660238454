import ButtonSquare from '@pretto/bricks/components/buttons/ButtonSquare'
import * as helpers from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled, { css } from 'styled-components'

export const Card = styled.div`
  ${helpers.boxShadow('card')};
  background-color: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(2)};
  position: relative;

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      padding-left: ${g(5.5)};
      padding-right: ${g(5.5)};

      ${ButtonContainer} {
        right: ${g(5.5)};
      }
    `}
`
export const Highlight = styled.div`
  ${typo.heading32};
  ${typo.isNumber};
  margin-top: ${g(1)};
  margin-bottom: ${g(1.5)};

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      color: ${({ theme }) => theme.colors.primary2};
    `}
`
export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.mobileM}) {
    align-items: center;
  }
`
export const ButtonContainer = styled.div`
  display: block;
  position: absolute;
  top: ${g(2)};
  right: ${g(2)};
  z-index: 1;
`
export const Button = styled(ButtonSquare).attrs({ size: 'small', variant: 'secondary' })``
export const Aside = styled.div`
  @media screen and (min-width: ${breakpoints.mobileM}) {
    display: flex;
  }
`
export const AsideLabel = styled.div`
  ${typo.caption12};
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.mobileM}) {
    & + & {
      margin-left: ${g(3)};
    }
  }
`
export const AsideNumber = styled.div`
  ${typo.bodyBold16};
  margin: 0 ${g(1)};

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      color: ${({ theme }) => theme.colors.primary2};
    `}
`
export const AsideSuffix = styled.span`
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      color: ${({ theme }) => theme.colors.primary2};
    `}
`
export const AsideRate = styled.div`
  ${typo.heading32};
  ${typo.isNumber};
  margin-top: ${g(1)};
  margin-bottom: ${g(1.5)};
`
export const Label = styled.div`
  ${typo.link12};
  display: flex;
  align-items: center;
`
