import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Result = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.neutral1}B2;
  border-radius: ${g(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: opacity 250ms ease-in-out;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      border-radius: 0;
    `}
`
export const OverlayContent = styled.p`
  width: ${g(36)};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`
