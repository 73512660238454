import PropTypes from 'prop-types'
import { memo } from 'react'

import { ERROR_TYPES, getErrorByType } from '../config'

import * as S from './styles'

export const BlocErrorCondensed = memo(({ flag, ...props }) => {
  const { illustration, message } = getErrorByType(flag)
  return (
    <S.Card {...props}>
      <S.IllustrationContainer>
        <S.Illustration $name={illustration} />
      </S.IllustrationContainer>

      {message}
    </S.Card>
  )
})

BlocErrorCondensed.propTypes = {
  flag: PropTypes.oneOf(ERROR_TYPES).isRequired,
}

BlocErrorCondensed.displayName = 'BlocErrorCondensed'
