import { formatInputNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'

import { APP_HREF } from '../../../config'

import * as S from './styles'

export const ResultCondensed = memo(
  ({
    buttonHref = APP_HREF,
    label,
    dataList,
    highlightValue,
    isFixed,
    onClickButton,
    highlightSuffix,
    isEmpty,
    ...props
  }) => (
    <S.Card {...props} $isFixed={isFixed}>
      <S.Label>{label}</S.Label>

      <S.Content>
        <S.Highlight $isEmpty={isEmpty}>
          {formatInputNumber(highlightValue)} {highlightSuffix}
        </S.Highlight>

        <S.ButtonContainer>
          <S.Button href={buttonHref} onClick={onClickButton} />
        </S.ButtonContainer>

        <S.Aside>
          {dataList.map(({ label, value, suffix }, i) => (
            <S.AsideLabel key={i}>
              {label} :{' '}
              <S.AsideNumber $isEmpty={isEmpty}>
                {formatInputNumber(value)} {suffix}
              </S.AsideNumber>
            </S.AsideLabel>
          ))}
        </S.Aside>
      </S.Content>
    </S.Card>
  )
)

ResultCondensed.propTypes = {
  buttonHref: PropTypes.string,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  highlightSuffix: PropTypes.node.isRequired,
  highlightValue: PropTypes.number.isRequired,
  isEmpty: PropTypes.bool,
  isFixed: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
}

ResultCondensed.displayName = 'ResultCondensed'
