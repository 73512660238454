import PropTypes from 'prop-types'
import { memo } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

export const Loader = memo(({ isLoading, isFixed }) => (
  <Transition in={isLoading} timeout={300} unmountOnExit>
    {state => (
      <S.LoadingSection $isLoading={state === 'entered'} $isFixed={isFixed}>
        <S.LoadingBackground $isFixed={isFixed} />
        <S.Spinner $isFixed={isFixed} />
      </S.LoadingSection>
    )}
  </Transition>
))

Loader.propTypes = {
  isFixed: PropTypes.bool,
  isLoading: PropTypes.bool,
}

Loader.displayName = 'Loader'
