import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled, { css } from 'styled-components'

export const Content = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 8])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ $isSidebarMenuActive }) =>
      $isSidebarMenuActive &&
      css`
        ${column([2, 10])};
      `}
  }
`

export const InnerPage = styled.div`
  ${column([1, 6])};
  ${grid(4)};
  background-color: white;
  padding-bottom: ${g(6)};
  padding-top: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    ${grid(8)};
    padding-bottom: ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const Page = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`
