import { breakpoints, g } from '@pretto/bricks/components/layout'
import SpinnerSimulation from '@pretto/bricks/components/loading/SpinnerSimulation'
import styled, { css } from 'styled-components'

export const LoadingSection = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms ease-in-out;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      transition: opacity 250ms ease-in-out;
      visibility: visible;
      opacity: 1;
    `}

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      flex-direction: row;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
  }
`
export const LoadingBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  opacity: 0.95;
  z-index: -1;

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      border-radius: 0;
      opacity: 1;
    `}
`
export const Spinner = styled(SpinnerSimulation)`
  ${({ $isFixed }) =>
    $isFixed &&
    css`
      transform: scale(0.35);
    `}
`
