import * as helpers from '@pretto/bricks/components/helpers'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import styled from 'styled-components'

export const Card = styled(ResponsiveSSR).attrs({ max: 'tablet' })`
  ${helpers.boxShadow('card')}
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  padding: ${g(2)} ${g(3)};
  position: relative;
`

export const IllustrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`
export const Illustration = styled(IllustrationDeprecated).attrs(({ $name }) => ({ name: $name }))`
  width: ${g(10)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(16)};
  }
`
