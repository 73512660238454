import { numbers16 } from '@pretto/bricks/core/typography'
import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'
import styled from 'styled-components'

import { APP_HREF } from '../../../../config'
import { ResultCondensed } from '../../Condensed'

import * as S from './styles'

export const ResultRenegotiationCondensed = memo(
  ({ savings, payment, onClickButton, rate, isEmpty, isFixed, isOverlayVisible, ...props }) => (
    <S.Result>
      <S.Overlay $isFixed={isFixed} $isVisible={isOverlayVisible}>
        <S.OverlayContent>
          Remplissez tous les champs
          <br />
          pour voir le résulat
        </S.OverlayContent>
      </S.Overlay>
      <ResultCondensed
        buttonHref={`${APP_HREF}project/renegotiation/introduction`}
        dataList={[
          { label: 'Économies', suffix: '€', value: roundNumber(savings) },
          { label: 'Taux', suffix: '%', value: roundNumber(rate, 0.01) },
        ]}
        highlightValue={roundNumber(payment)}
        highlightSuffix={<Suffix>€ / mois</Suffix>}
        label="Nouvelle mensualité"
        onClickButton={onClickButton}
        isEmpty={isEmpty}
        isFixed={isFixed}
        {...props}
      />
    </S.Result>
  )
)

ResultRenegotiationCondensed.propTypes = {
  isEmpty: PropTypes.bool,
  isFixed: PropTypes.bool,
  isOverlayVisible: PropTypes.bool,
  onClickButton: PropTypes.func.isRequired,
  payment: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
}

ResultRenegotiationCondensed.displayName = 'ResultRenegotiationCondensed'

const Suffix = styled.span`
  ${numbers16};
`
