import PropTypes from 'prop-types'
import { useRef } from 'react'

import * as S from './styles'

const SimulatorCard = ({ content, cta, href, illustrationSlug, title }) => {
  const linkRef = useRef()

  const handleClick = () => {
    linkRef.current.click()
  }
  return (
    <S.Card onClick={handleClick}>
      <S.Picture name={illustrationSlug} />
      <S.Title>{title}</S.Title>
      <S.Content>{content}</S.Content>
      <S.Link href={href} label={cta} ref={linkRef} />
    </S.Card>
  )
}

SimulatorCard.propTypes = {
  content: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  illustrationSlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SimulatorCard
