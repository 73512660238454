import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import * as typo from '@pretto/bricks/core/typography'
import { Date as DateComponent } from '@pretto/bricks/website/calculator2/components/Date'
import { Graph as GraphComponent } from '@pretto/bricks/website/calculator2/components/Graph'
import { Radio as RadioComponent } from '@pretto/bricks/website/calculator2/components/Radio'
import { Slider as SliderComponent } from '@pretto/bricks/website/calculator2/components/Slider'

import styled, { css } from 'styled-components'

import { BlocInfo as BlocInfoComponent } from '../../components/BlocInfo'

const CARD_STYLE = css`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(4)};
    padding-right: ${g(4)};
  }
`
export const Wrapper = styled.div`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-gap: ${g(3)};
    grid-template-columns: repeat(8, 1fr);
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 0;
    padding-right: 0;
  }
`
export const Block = styled.div`
  ${grid()};
`
export const WrapperHero = styled(Wrapper)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(6)} 0;
  }
`
export const HeroContainer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-column: 2 / -2;
    border-radius: ${g(1)} ${g(1)} 0 0;
  }
`
export const ResultCardCondensed = styled.div`
  margin-top: ${g(2)};
`
export const FixedResultsBannerContainer = styled.div`
  position: fixed;
  transform: translateY(-100%);
  transition: transform 250ms ease-in-out, visibility 0ms linear 250ms;
  top: ${g(11)};
  right: 0;
  left: 0;
  z-index: 99;
  visibility: hidden;

  ${({ $isOutside }) =>
    $isOutside &&
    css`
      transition: transform 250ms ease-in-out;
      visibility: visible;
      transform: translateY(0);
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const FixedResultBanner = styled.div`
  && {
    border-radius: 0;
  }
`
export const BlockInputs = styled(Block)`
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: unset;
    margin-top: ${g(1)};
  }
`
export const WrapperInputs = styled(Wrapper)`
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(4)} 0;
  }
`
export const Inputs = styled.div`
  ${CARD_STYLE}

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: initial;
    border-radius: initial;
    padding: initial;
    margin-bottom: ${g(3)};
    grid-column: 2 / -2;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2 / span 5;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: ${g(7)};
  }
`
export const Divider = styled.div`
  margin: ${g(3)} 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const InputSection = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${Divider} + & {
      margin-top: ${g(5)};
    }
  }
`
export const InputSectionTitle = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading32};
    display: block;
    margin-bottom: ${g(2)};
    display: flex;
    align-items: center;
  }
`
export const InputSectionIllustration = styled.div`
  height: ${g(7)};
  width: ${g(7)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin-right: ${g(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
export const HouseIllustration = styled(Illustration).attrs({
  height: 48,
  path: 'v1637684214/zen/static-assets/illustrations/660x660/house1.svg',
  width: 45,
})`
  width: 45px;
  height: 48px;
`
export const WomanSmilingIllustration = styled(Illustration).attrs({
  height: 56,
  path: 'v1637685718/zen/static-assets/illustrations/660x660/frame-woman-smiling.svg',
  width: 56,
})`
  transform: translateY(${g(1)});
  width: ${g(7)};
  height: ${g(7)};
`
export const SatisfiedIllustration = styled(WomanSmilingIllustration).attrs({
  path: 'v1629988872/bricks/static-assets/illustrations/Satisfied_transparent.svg',
})``
export const CoupleIllustration = styled(Illustration).attrs({
  height: 43,
  path: 'v1622808009/bricks/static-assets/illustrations/Couple.svg',
  width: 52,
})`
  width: 52px;
  height: 43px;
  align-self: flex-end;
  transform: translateY(2px);
`
export const InputCard = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${CARD_STYLE}
  }
`
export const MoreInformation = styled(ButtonInline).attrs({ isSecondary: true })`
  margin-top: ${g(5)};
`
const InputComponent = styled.div`
  & + & {
    margin-top: ${g(3)};
  }
`
export const Slider = styled(InputComponent).attrs({ as: SliderComponent })``
export const Radio = styled(InputComponent).attrs({ as: RadioComponent })``
export const Date = styled(InputComponent).attrs({ as: DateComponent })``
export const BlocInfo = styled(BlocInfoComponent)`
  margin-top: ${g(3)};
`
export const ResultSection = styled(ResponsiveSSR)`
  grid-column: 2 / -2;

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 7 / -2;
  }
`
export const ResultSectionTitle = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading32};
    display: block;
    margin-bottom: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(1)};
  }
`
export const ResultContainer = styled.div`
  position: relative;
`
export const Result = styled.div``
export const Graph = styled(GraphComponent)`
  margin-top: ${g(3)};
`
export const BlockSeparator = styled(Block)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(1)};
    margin-bottom: ${g(1)};
  }
`
export const WrapperSeparator = styled.div`
  ${column([1, -1])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
  }
`
export const SectionSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.primary2};
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${g(3)};
    opacity: 0.3;
    content: '';
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, ${({ theme }) => theme.colors.primary2}, transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(-90deg, ${({ theme }) => theme.colors.primary2}, transparent);
  }
`
export const SeparatorTitle = styled.div`
  ${typo.headline16};
  color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)} ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} calc(100% / 8) 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: calc(100% / 12);
    padding-right: calc(100% / 12);
  }
`
export const SeparatorCards = styled.ul`
  margin-top: ${g(4)};
  display: flex;
  overflow: auto;
  padding: 0 ${g(3)} ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: calc(100% / 8);
    padding-right: calc(100% / 8);
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: calc(100% / 12);
    padding-right: calc(100% / 12);
  }
`
