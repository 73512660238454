import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import { memo } from 'react'

import { APP_HREF } from '../../config'

import * as S from './styles'

export const Hero = memo(({ buttonLabel, onClickButton, description, projectKind, title, ...props }) => (
  <S.Hero {...props}>
    <div>
      <S.Title>{title}</S.Title>

      <S.Description>
        <Markdown options={{ overrides: defaultOverrides }}>{description}</Markdown>
      </S.Description>

      <ResponsiveSSR min="tablet">
        <S.ButtonInline href={APP_HREF} onClick={onClickButton}>
          {buttonLabel}
        </S.ButtonInline>
      </ResponsiveSSR>
    </div>
    <S.Illustration $projectKind={projectKind} />
  </S.Hero>
))

Hero.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
  projectKind: PropTypes.oneOf(['capacity', 'purchase', 'renegotiation']).isRequired,
  title: PropTypes.string.isRequired,
}

Hero.displayName = 'Hero'
