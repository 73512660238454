import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'

import { ResultCondensed } from '../../Condensed'

export const ResultCapacityCondensed = memo(({ capacity, creditCost, onClickButton, rate, ...props }) => (
  <ResultCondensed
    dataList={[
      { label: 'Taux', suffix: '%', value: roundNumber(rate, 0.01) },
      { label: 'Coût', suffix: '€', value: roundNumber(creditCost) },
    ]}
    highlightValue={roundNumber(capacity)}
    highlightSuffix="€"
    label="Capacité d’emprunt"
    onClickButton={onClickButton}
    {...props}
  />
))

ResultCapacityCondensed.propTypes = {
  capacity: PropTypes.number.isRequired,
  creditCost: PropTypes.number.isRequired,
  onClickButton: PropTypes.func.isRequired,
  rate: PropTypes.number.isRequired,
}

ResultCapacityCondensed.displayName = 'ResultCapacityCondensed'
