import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'

import { ResultCondensed } from '../../Condensed'

export const ResultPurchaseCondensed = memo(({ creditCost, onClickButton, payment, rate, ...props }) => (
  <ResultCondensed
    dataList={[
      { label: 'Mensualité', suffix: '€', value: roundNumber(payment) },
      { label: 'Coût', suffix: '€', value: roundNumber(creditCost) },
    ]}
    highlightValue={roundNumber(rate, 0.01)}
    highlightSuffix="%"
    label="Taux estimé"
    onClickButton={onClickButton}
    {...props}
  />
))

ResultPurchaseCondensed.propTypes = {
  creditCost: PropTypes.number.isRequired,
  onClickButton: PropTypes.func.isRequired,
  payment: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
}

ResultPurchaseCondensed.displayName = 'ResultPurchaseCondensed'
