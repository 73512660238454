import ButtonInlineComponent from '@pretto/bricks/components/buttons/ButtonInline'
import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

import * as ILLUSTRATION_PATH from '../../config/illustrations'

export const Hero = styled.div`
  display: flex;
  align-items: center;
`
export const Title = styled.h1`
  ${typo.heading32}

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${typo.heading48};
  }
`
export const Description = styled.div`
  margin-top: ${g(2)};
`
export const ButtonInline = styled(ButtonInlineComponent).attrs({ isSecondary: true })`
  margin-top: ${g(2)};
`
export const Illustration = styled(IllustrationComponent).attrs(({ $projectKind }) => ({
  height: 132,
  path: ILLUSTRATION_PATH[$projectKind],
  width: 132,
}))`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    width: 132px;
    height: 132px;
    margin-left: ${g(3)};
  }
`
