import { useScrollPosition } from '@pretto/bricks/assets/utility/useScrollPosition'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { Hero } from '@pretto/bricks/website/calculator2/components/Hero'
import SimulatorCard from '@pretto/bricks/website/calculator2/components/SimulatorCard'

import PropTypes from 'prop-types'
import { createElement, Fragment, useEffect, useRef } from 'react'

import { BlocErrorCondensed } from '../../components/BlocError/Condensed'
import { BlocErrorMain } from '../../components/BlocError/Main'
import { Loader } from '../../components/Loader'
import { ResultCapacityCondensed } from '../../components/Result/Capacity/Condensed'
import { ResultCapacityMain } from '../../components/Result/Capacity/Main'
import { ResultPurchaseCondensed } from '../../components/Result/Purchase/Condensed'
import { ResultPurchaseMain } from '../../components/Result/Purchase/Main'
import { ResultRenegotiationCondensed } from '../../components/Result/Renegotiation/Condensed'
import { ResultRenegotiationMain } from '../../components/Result/Renegotiation/Main'

import * as S from './styles'

const INPUT_COMPONENT = {
  date: S.Date,
  radio: S.Radio,
  slider: S.Slider,
}
const ILLUSTRATION = {
  couple: <S.CoupleIllustration />,
  house: <S.HouseIllustration />,
  satisfied: <S.SatisfiedIllustration />,
  single: <S.WomanSmilingIllustration />,
}
const RESULT_TITLE = {
  capacity: 'Votre capacité',
  purchase: 'Votre prêt',
  renegotiation: 'Votre nouveau prêt',
}
const RESULT_CARD_MAIN = {
  capacity: ResultCapacityMain,
  purchase: ResultPurchaseMain,
  renegotiation: ResultRenegotiationMain,
}
const RESULT_CARD_CONDENSED = {
  capacity: ResultCapacityCondensed,
  purchase: ResultPurchaseCondensed,
  renegotiation: ResultRenegotiationCondensed,
}

const HEADER_HEIGHT = 88

const Calculator = ({
  errorProps,
  graphProps,
  heroProps,
  inputBlocks,
  isError,
  isGraphActive,
  isSimulatorsSectionDisplayed,
  isLoading,
  onClickComplete,
  onClickDescriptionButton,
  resultProps,
  simulatorCards,
  type,
  ticks,
}) => {
  const { y } = useScrollPosition()
  const yLimit = useRef()
  const resultsRef = useRef()

  useEffect(() => {
    // Let yLimit be updated because card is going to have a different height
    if (yLimit.current) {
      yLimit.current.update = true
    }
  }, [isError])

  useEffect(() => {
    if (resultsRef.current && (!yLimit.current || yLimit.current.update)) {
      const rect = resultsRef.current.getBoundingClientRect()
      yLimit.current = { update: false, value: y + rect.top + rect.height - HEADER_HEIGHT }
    }
  }, [y])

  const isResultCardOffScreen = typeof window !== 'undefined' && y > yLimit.current?.value

  const cardCondensedProps = isError
    ? { as: BlocErrorCondensed, ...errorProps }
    : { as: RESULT_CARD_CONDENSED[type], ...resultProps }

  const cardMainProps = isError
    ? { as: BlocErrorMain, ...errorProps }
    : { as: RESULT_CARD_MAIN[type], ...resultProps, ticks }

  return (
    <>
      <S.Block>
        <S.WrapperHero>
          <S.HeroContainer>
            <Hero {...heroProps} projectKind={type} />
          </S.HeroContainer>

          <ResponsiveSSR ref={resultsRef} max="tablet">
            <S.ResultCardCondensed {...cardCondensedProps} />
          </ResponsiveSSR>

          <S.FixedResultsBannerContainer $isOutside={isResultCardOffScreen}>
            <S.FixedResultBanner {...cardCondensedProps} isFixed />

            {isResultCardOffScreen && <Loader isLoading={isLoading} isFixed />}
          </S.FixedResultsBannerContainer>
        </S.WrapperHero>
      </S.Block>

      <S.BlockInputs>
        <S.WrapperInputs>
          <S.Inputs>
            {inputBlocks.map((block, i) => {
              const isFirst = i === 0
              const isLast = i === inputBlocks.length - 1

              return (
                <Fragment key={i}>
                  {!isFirst && <S.Divider />}
                  <S.InputSection>
                    <S.InputSectionTitle>
                      <S.InputSectionIllustration>{ILLUSTRATION[block.illustration]}</S.InputSectionIllustration>
                      {block.title}
                    </S.InputSectionTitle>

                    <S.InputCard>
                      {block.inputs.map(({ type, props }, i) =>
                        createElement(INPUT_COMPONENT[type], { key: i, ...props })
                      )}

                      {isLast && (
                        <S.MoreInformation href="https://app.pretto.fr/" onClick={onClickComplete}>
                          Je complète mon profil
                        </S.MoreInformation>
                      )}
                    </S.InputCard>
                  </S.InputSection>
                </Fragment>
              )
            })}
          </S.Inputs>

          <ResponsiveSSR max="tablet">
            {!isResultCardOffScreen && <Loader isLoading={isLoading} />}
            <S.BlocInfo onClickButton={onClickDescriptionButton} projectKind={type} ticks={ticks} />
          </ResponsiveSSR>

          <S.ResultSection min="tablet">
            <S.ResultSectionTitle>{RESULT_TITLE[type]}</S.ResultSectionTitle>

            <S.ResultContainer>
              <Loader isLoading={isLoading} />
              <S.Result {...cardMainProps} />
            </S.ResultContainer>

            {isGraphActive && <S.Graph {...graphProps} />}
          </S.ResultSection>
        </S.WrapperInputs>
      </S.BlockInputs>

      {isSimulatorsSectionDisplayed && (
        <S.BlockSeparator>
          <S.WrapperSeparator>
            <S.SectionSeparator>
              <S.SeparatorTitle>Réussissez votre prêt immobilier grâce à nos autres simulateurs !</S.SeparatorTitle>
              <S.SeparatorCards>
                {simulatorCards.map((cardProps, i) => (
                  <SimulatorCard key={i} {...cardProps} />
                ))}
              </S.SeparatorCards>
            </S.SectionSeparator>
          </S.WrapperSeparator>
        </S.BlockSeparator>
      )}
    </>
  )
}

Calculator.propTypes = {
  errorProps: PropTypes.object,
  graphProps: PropTypes.shape({
    bars: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number.isRequired,
        isActive: PropTypes.bool,
        principal: PropTypes.number.isRequired,
        totalCost: PropTypes.number.isRequired,
        totalPayment: PropTypes.number.isRequired,
      })
    ).isRequired,
    paymentSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
    principalSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  heroProps: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  inputBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      illustration: PropTypes.oneOf(Object.keys(ILLUSTRATION)).isRequired,
      inputs: PropTypes.arrayOf(
        PropTypes.shape({
          props: PropTypes.object.isRequired,
          type: PropTypes.oneOf(Object.keys(INPUT_COMPONENT)).isRequired,
        })
      ).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  isError: PropTypes.bool,
  isGraphActive: PropTypes.bool,
  isSimulatorsSectionDisplayed: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClickComplete: PropTypes.func.isRequired,
  onClickDescriptionButton: PropTypes.func.isRequired,
  resultProps: PropTypes.object.isRequired,
  simulatorCards: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      illustrationSlug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  type: PropTypes.oneOf(Object.keys(RESULT_CARD_MAIN)).isRequired,
  ticks: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Calculator
