import { useEffect, useState } from 'react'

export const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return { x: 0, y: 0 }
  }

  return { x: window.pageXOffset, y: window.pageYOffset }
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition())

  useEffect(() => {
    let requestedAnimationFrame = null

    const handleAnimationFrame = () => {
      setScrollPosition(getScrollPosition())
      requestedAnimationFrame = null
    }

    const handleScroll = () => {
      if (requestedAnimationFrame === null) {
        requestedAnimationFrame = window.requestAnimationFrame(handleAnimationFrame)
      }
    }

    window.addEventListener('scroll', handleScroll)
    setScrollPosition(getScrollPosition())

    return () => {
      if (requestedAnimationFrame !== null) {
        window.cancelAnimationFrame(requestedAnimationFrame)
      }

      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return scrollPosition
}
