import CalculatorPage from '@pretto/bricks/website/calculator2/pages/Calculator'

import { BANKS_NUMBER, SIMULATION_DURATION } from '@pretto/website/src/config/reassurance'
import { SimulateurAvance as SimulateurAvanceView } from '@pretto/website/src/templates/simulateurAvance/views/SimulateurAvance/SimulateurAvance'
import { useTracking } from '@pretto/website/src/utilities/tracking'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { useSimulator } from './lib/useSimulator'

export const SimulateurAvance = ({ data, ...props }) => {
  const {
    template: {
      calculatorV2: { buttonLabel, isSimulatorsSectionDisplayed, description, simulators, type },
    },
    title,
  } = data

  const trackAction = useTracking()

  const trackButtonClick = location => {
    trackAction('Calculator CTA clicked', { calculator_v2_cta: location })
  }

  const handleClickHeaderButton = () => {
    trackButtonClick('header')
  }

  const handleClickDescriptionButton = () => {
    trackButtonClick('description')
  }

  const handleClickErrorButton = () => {
    trackButtonClick(`error_${errorFlag}`)
  }

  const handleClickProfileButton = () => {
    trackButtonClick('profile')
  }

  const heroProps = {
    buttonLabel,
    description,
    onClickButton: handleClickHeaderButton,
    title,
  }

  const hookData = {
    capacity: {
      trackButtonClick,
    },
    purchase: {
      trackButtonClick,
    },
    renegotiation: {
      trackButtonClick,
    },
  }

  const { errorFlag, isGraphActive, graphProps, inputBlocks, resultProps } = useSimulator(type, hookData[type])

  const errorProps = {
    flag: errorFlag,
    onClickButton: handleClickErrorButton,
  }

  const isError = !!errorFlag

  const simulatorCards = isSimulatorsSectionDisplayed
    ? simulators.map(({ relatedPost, ...simulator }) => ({
        ...simulator,
        href: relatedPost.uri,
      }))
    : []

  const calculatorPageProps = {
    errorProps,
    graphProps,
    heroProps,
    inputBlocks,
    isError,
    isGraphActive,
    isSimulatorsSectionDisplayed,
    onClickComplete: handleClickProfileButton,
    onClickDescriptionButton: handleClickDescriptionButton,
    resultProps: {
      ...resultProps,
    },
    simulatorCards,
    ticks: ['Gratuit', `${BANKS_NUMBER.toString()}+ banques comparées`, `${SIMULATION_DURATION.toString()} min`],
    type,
  }

  const headerComponent = useMemo(() => <CalculatorPage {...calculatorPageProps} />, [calculatorPageProps])

  return <SimulateurAvanceView {...props} headerComponent={headerComponent} />
}

SimulateurAvance.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      calculatorV2: PropTypes.shape({
        buttonLabel: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isSimulatorsSectionDisplayed: PropTypes.bool,
        simulators: PropTypes.arrayOf(
          PropTypes.shape({ relatedPost: PropTypes.shape({ uri: PropTypes.string.isRequired }).isRequired }).isRequired
        ),
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment SimulateurAvanceTemplate on WpTemplate_SimulateurAvanc {
    calculatorV2 {
      buttonLabel: buttonlabel
      description
      isSimulatorsSectionDisplayed: simulatorsDisplay
      simulators {
        content
        cta
        illustrationSlug: illustrationslug
        relatedPost: relatedpost {
          ... on WpPost {
            uri
          }
        }
        title
      }
      type
    }
  }
`
