import ButtonFeature from '@pretto/bricks/components/buttons/ButtonFeature'
import IllustrationDeprecated from '@pretto/bricks/components/images/IllustrationDeprecated'
import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

export const Card = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding: ${g(3)};
  flex: 1 0 ${g(30, 2)};
  transition: border-color 300ms ease-in-out;

  & + & {
    margin-left: ${g(2)};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral2};
    cursor: pointer;
  }
`
export const Title = styled.div`
  ${typo.heading16};
  margin-top: ${g(3)};
`
export const Content = styled.div`
  ${typo.caption12};
  margin-top: ${g(1)};
`
export const Link = styled(ButtonFeature)`
  margin-top: ${g(2)};
`
export const Picture = styled(IllustrationDeprecated).attrs({ height: 88, width: 130 })`
  width: auto;
  margin: 0 auto;
  display: block;
`
